import React, { useContext, useEffect, useState } from 'react'
import { Button, Modal, Form, Table, Tooltip } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook } from '@fortawesome/free-solid-svg-icons';
import { useForm } from '../../../../hooks/useForm';
import { useValidator } from '../../../../hooks/useValidator';
import { InputSelect} from '../../../../components/Inputs';
import { ButtonSpinner } from '../../../../components/ButtonSpinner/ButtonSpinner';
import { useAxios } from '../../../../hooks/useAxios';
import { MsgInputServer } from '../../../../components/MsgInput/MsgInputServer';
import { Loader } from '../../../../components/Loader/Loader';

export const ModalRema = ({data, handleCloseModal, consultor, setConsultor, reloadComisiones}) => {
     const [fetchCambiarRema, fetchCambiarRemaData] = useAxios('PATCH', `/comisiones/cambiar_rema`); 
     const [fetchConsultores, fetchConsultoresData] = useAxios('get', `/consultores/obtener_listado`)  
     const [formRema, 
        handleInputChangeRema, 
        handleFormChangeRema, 
        handleResetRema] = useForm({rema_id: '', sincronizar: ''})

     const [valuesValidRema, handleVerifiedValuesRema, handleResetValuesRema] = useValidator({
        rema_id: { isOk: true, msgError: [`Ingrese un Rema`] },
     })

     const handleSubmitUpdateRema = (e) => {
        fetchCambiarRema({
            id: consultor?.org_id,
            body: formRema
        })
    }

    useEffect(() => {
        if(data?.value){
            fetchConsultores({
                params:{
                    es_rema: true
                }
            });
            handleFormChangeRema({...formRema, rema_id: consultor?.isConsultor});
        }
    }, [data?.value])

    useEffect(() => {
        if (fetchCambiarRemaData?.data?.data?.data) {
           const data = fetchCambiarRemaData.data?.data?.data; 
           const fullName = data.consultor_nombre +' '+data.consultor_apellidos +' '+data.consultor_nif;
           setConsultor(
            {...consultor,
             isConsultor: data.id,
             fullName
            }
           )
           handleResetRema();
           //reloadComisiones(data.id)
           handleCloseModal();
            setTimeout(() => {
                window.location.reload();
            },100)
        }else if(fetchCambiarRemaData?.data?.data?.status === 'Success'){
            handleCloseModal();
            setTimeout(() => {
                window.location.reload();
            },100)
            
        }

    }, [fetchCambiarRemaData.data])

    const handleChangeSincronizar = (e) => {
        handleFormChangeRema({
            ...formRema,
            sincronizar: e.target.checked ? true : ''
        })
    }

	return (
        <Modal show={data?.value} onHide={handleCloseModal}>
            <Modal.Header closeButton>
                <Modal.Title className='h5'>Asignar REMA</Modal.Title>
            </Modal.Header>
             {fetchConsultoresData.loading ? <Loader /> :
                <Modal.Body className='px-4 py-4'>
                    <Form.Group className="mb-3 col-lg-12">
                        <InputSelect
                            //label='REMA:'
                            name='rema_id'
                            values={
                                fetchConsultoresData.data?.data?.data?.map((item, index) => {
                                    return {
                                       id: item.id,
                                       nombre: item.full_name
                                    }
                                })
                              }
                            value={formRema.rema_id}
                            onChange={handleInputChangeRema}
                            validation={handleVerifiedValuesRema}
                            fetchData={fetchCambiarRemaData}
                        />        
                     </Form.Group>
                     {formRema.rema_id &&
                        <Form.Group className=" mb-3 col-lg-12">
                            <Form.Check
                                type='checkbox'
                                name='renovacion'
                                value={formRema.sincronizar}
                                onChange={handleChangeSincronizar}
                                id={`default-checkbox`}
                                className="mb-0 fw-500"
                                label='Sincronizar'
                            />
                       </Form.Group>
                    }
                     <div className="col-12 text-center">
                        <MsgInputServer fetchData={fetchCambiarRemaData} className='mt-2' />
                     </div>  
                </Modal.Body>
            }
            <Modal.Footer>
                <ButtonSpinner
                    variant="primary" type="submit" className="ms-2"
                    handleSubmit={handleSubmitUpdateRema} fetchDataLoading={[fetchCambiarRemaData.loading]}
                    value='Guardar' icon={<FontAwesomeIcon icon={faBook} className='me-1' />}
                />   
                <Button variant="default"  onClick={handleCloseModal}>
                    Salir
                </Button>
            </Modal.Footer>
           
        </Modal>
     )   
}