import React, { useContext, useEffect, useState, useRef } from 'react'
import { Button, Modal, OverlayTrigger, Table, Tooltip, Badge } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBook, faCheckCircle, faFile, faFileExcel, faSort, faInfoCircle, faCogs, faEye, faClone, faFilter, faTrashAlt } from '@fortawesome/free-solid-svg-icons';
import { Loader } from '../../../components/Loader/Loader';
import { PaginationT } from '../../../components/PaginationT/PaginationT';

import { useLang } from '../../../language/index';
import { useHistory } from 'react-router-dom';
import { ModalCrearDAPcons } from './ModalCrearDAPcons';
import ButtonOrderBy from '../../../components/ButtonOrderBy/ButtonOrderBy';
import { useAxios } from '../../../hooks/useAxios';
import { AuthContext } from '../../../auth/AuthContext';
import { getDataFile, isModuleAdministrador, isModuleOrganizacion } from '../../../helpers/helpers';

import './DAPconsMain.css'
import { imagesFlags } from '../../../helpers/imagesFlags';
import { images } from '../../../helpers/images';
import { ModalStatusServer2 } from '../../../components/ModalStatusServer/ModalStatusServer2';
import { ButtonSpinner } from '../../../components/ButtonSpinner/ButtonSpinner';
import { useModalData } from '../../../hooks/useModalData';
import {ModalDuplicar} from './ModalDuplicar'
import {ModalEliminarDapcons} from './ModalEliminarDapcons'
import {isAdmin} from '../../../helpers/helpers'
import {ResumenDapcons} from '../../../components/ResumenDapcons/ResumenDpacons'

export const DAPconsMain = ({ fetchDAPconsData, formValuesSearch, namePage = 'default', handleFormChange, modulo_app,
    estaVerificando, idiomas, fetchIdiomasData, fetchEstadosDAPconsData, fetchDAPcons, handleOpenModal_Filter }) => {

    const [fetchResumen, fetchResumenData] = useAxios('get', `/dapcons/resumen`)
    const [dataModal_Duplicar, handleOpenModal_Duplicar, handleCloseModal_Duplicar] = useModalData()
    const [dataModal_Dapcons, handleOpenModal_Dapcons, handleCloseModal_Dapcons] = useModalData()
    const [dataModal_Confirm, handleOpenModal_Confirm, handleCloseModal_Confirm] = useModalData()
    // console.log('fetchDAPconsData', fetchDAPconsData)
    const history = useHistory();
    const { user } = useContext(AuthContext);

    const Lang = useLang();

    // Crear DAPcons basico

    const [showModalCrearDAPcons, setShowModalCrearDAPcons] = useState(false);

    const handleCloseModalCrearDAPcons = () => setShowModalCrearDAPcons(false)
    const handleShowModalCrearDAPcons = () => setShowModalCrearDAPcons(true);

    /*const [fetchRCP, fetchRCPData] = useAxios('get', `/rcp`)
    const [fetchVerificadores, fetchVerificadoresData] = useAxios('get', `/verificadores`)

    useEffect(() => {
        fetchRCP()
        fetchVerificadores()
    }, [])*/

    const [arrayTempDAPcons, setArrayTempDAPcons] = useState([])

    useEffect(() =>{
        if(isModuleAdministrador(modulo_app)){
            fetchResumen()
        }
    },[])

    useEffect(() => {
        if (fetchDAPconsData.data) {

            let arrayTemp = fetchDAPconsData?.data?.data?.data?.data
            setArrayTempDAPcons(arrayTemp)

        }
    }, [fetchDAPconsData])


    // console.log(idiomas)
    const [fetchCrearDAPcons, fetchCrearDAPconsData] = useAxios('POST', '/dapcons/alta')

    const [showModalSuccess, setShowModalSuccess] = useState(false);

    const handleCloseModalSuccess = () => setShowModalSuccess(false)
    const handleShowModalSuccess = () => setShowModalSuccess(true);

    // useEffect(() => {

    //     if (fetchCrearDAPconsData?.data?.status === 201) {
    //         // setShowSuccess(true)
    //         // fetchCheckForms({
    //         //     params: {
    //         //         idioma_id: idiomaDAPcon
    //         //     }
    //         // })
    //     }

    // }, [fetchCrearDAPconsData])
    /*
    const [dataHtml, setdataHtml] = useState('')
    const inputEl = useRef(null);
    useEffect(() => {
        // console.log(fetchCrearDAPconsData)
        if (fetchCrearDAPconsData.data?.status === 201) {
            const dapcons = fetchCrearDAPconsData.data?.data?.data;
            if(dapcons?.pagoVisaResult){
                setdataHtml(dapcons?.pagoVisaResult)
            }else{
                handleShowModalSuccess()
                handleCloseModalCrearDAPcons()
            }
        }
    }, [fetchCrearDAPconsData])

    useEffect(() => {
        if (dataHtml) {
            if (inputEl.current) {
                try {
                    inputEl.current.children[0].submit()
                } catch (error) {
                    console.log(error);
                }
            }
        }
    }, [dataHtml])*/

    const handleClickContinuar = () => {

        history.push(`/DAPcons/${fetchCrearDAPconsData.data?.data?.data?.id}/1`)
    }


    // Start --- Handle Click Descargar Excel

    const [fetchDAPconsExcel, fetchDAPconsExcelData, resetfetchDAPconsExcelData]
        = useAxios('GET', `/dapcons`, '', 'blob')

    const handleClickDownloadFile = () => {

        let formValuesSearchTemp = { ...formValuesSearch }

        Object.keys(formValuesSearchTemp).forEach((k) => formValuesSearchTemp[k] === '' && delete formValuesSearchTemp[k]);

        fetchDAPconsExcel({
            params: {
                ...formValuesSearchTemp,
                to_excel: true
            }
        })
    }

    useEffect(() => {

        if (fetchDAPconsExcelData.data) {
            getDataFile(fetchDAPconsExcelData, resetfetchDAPconsExcelData, 'DAPcons', 'xlsx')
        }
    }, [fetchDAPconsExcelData])

    return (
        <>
            <div className={isModuleOrganizacion(modulo_app) ? 'col-12' : 'col-xl-12'}>
                <div className="MainInner shadow-sm animate__animated animate__fadeInUp d-flex flex-column w-auto">
                    <div className="d-flex justify-content-between mb-3 pb-2 flex-wrap">
                        <div>
                            <h5 className='mb-3 mb-lg-0'>{Lang('LISTA_DE')} {namePage}</h5>
                        </div>
                        <div>
                        {
                            isModuleOrganizacion(modulo_app) && <div className='d-flex justify-content-end ms-auto'>
                                <Button variant="primary" className="px-3 ms-3 d-flex align-items-center"
                                    onClick={() => {
                                        handleOpenModal_Dapcons({
                                            action: 'CREATE'
                                        })
                                    }}
                                >
                                    <FontAwesomeIcon icon={faFile} className='me-2' /> Alta <span className=''>&nbsp;DAPcon</span>
                                </Button>
                            </div>
                        }
                        {
                            isModuleAdministrador(modulo_app) &&
                            <>
                                <ButtonSpinner
                                    variant="outline-success" type="submit" className="ms-3"
                                    handleSubmit={handleClickDownloadFile} fetchDataLoading={[fetchDAPconsExcelData.loading]}
                                    value='Descargar Excel' icon={<FontAwesomeIcon icon={faFileExcel} className='me-2' />}
                                />
                            </>
                        }

                        {
                            isModuleAdministrador(modulo_app) &&
                            <Button variant="outline-primary" className="ms-3"
                             onClick={
                                () => handleOpenModal_Filter({
                                    show: 'FILTRO',
                                })
                                }
                            >
                                <FontAwesomeIcon icon={faFilter}/> Filtros de busqueda
                            </Button>
                        }   
                        </div>
                    </div>
                    {
                        fetchDAPconsData?.loading ?
                            <Loader /> :
                            <>
                                {
                                    arrayTempDAPcons.length > 0 && idiomas?.length > 0 ?

                                        <>

                                            {/*Resumen dapcons*/}
                                            {
                                                isModuleAdministrador(modulo_app) &&
                                                <ResumenDapcons
                                                    fetchResumenData={fetchResumenData}
                                                />
                                            }  
                                            {/*Resumen dapcons*/}
                                            <Table hover bordered responsive className='tableMain tableDAPcons'>
                                                <thead>
                                                    <tr>
                                                        <th className='text-nowrap text-center'>
                                                            {`${Lang('NUM')}. DAPcon`}
                                                        </th>
                                                        {
                                                            !isModuleOrganizacion(modulo_app) &&
                                                            <th className='text-nowrap'>
                                                                <ButtonOrderBy
                                                                    formValuesSearch={formValuesSearch}
                                                                    handleFormChange={handleFormChange}
                                                                    label={`Empresa`}
                                                                    id='nombre_empresa'
                                                                />
                                                            </th>
                                                        }

                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Producto`}
                                                                id='nombre_producto'
                                                            />
                                                        </th>
                                                        {
                                                            isModuleOrganizacion(modulo_app) &&
                                                            <th className='text-nowrap'>Consultor</th>
                                                        }

                                                        {/* <th className='text-nowrap'>Verificador</th> */}

                                                        <th className='text-nowrap'>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={`Estado`}
                                                                id='dapcons_estado'
                                                            />
                                                        </th>
                                                        <th className=''>
                                                            <ButtonOrderBy
                                                                formValuesSearch={formValuesSearch}
                                                                handleFormChange={handleFormChange}
                                                                label={Lang('FECHA_ESTADO')}
                                                                id='dapcons_fecha_estado'
                                                                className='text-center'
                                                                disabledNoWrapText={true}
                                                            />
                                                        </th>

                                                        <th className='text-center'>
                                                            Fecha Fin
                                                        </th>

                                                        <th className='text-center'>Idioma</th>
                                                        {
                                                            isModuleAdministrador(modulo_app) &&
                                                            <th className='text-nowrap text-center'>
                                                                <img src={images('./ECO_Logo.png')} className="" alt="logo" width='40px' />

                                                            </th>
                                                        }
                                                        {
                                                            isModuleAdministrador(modulo_app) &&
                                                            <th className='text-center'>
                                                                Convenio
                                                            </th>
                                                        }
                                                        {
                                                            isModuleAdministrador(modulo_app) &&
                                                            <th className='text-center'>
                                                                Secuencia
                                                            </th>
                                                        }    
                                                        
                                                        <th className='text-center'>
                                                            <FontAwesomeIcon icon={faCogs} />
                                                        </th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {arrayTempDAPcons.map((item, index) => {
                                                        return <>
                                                            {
                                                                item['dapcons_valores'].map((item2, index2) => {

                                                                    // const estado = 
                                                                    return (
                                                                        <tr key={index2} className={`${index2 === 0 ? 'tr_new_dapcon' : ''} ${index % 2 !== 0 ? 'tr_odd_dapcon' : ''} ${item.dapcons_estado_param_id === 'pendiente_verificar_rcp' ? 'tr-red' : ''}`}
                                                                            style={{
                                                                                //backgroundColor: isModuleAdministrador(modulo_app) && item.organizacion.org_convenio ? '#76b2fe' : '',
                                                                                //color:  isModuleAdministrador(modulo_app) && item.organizacion.org_convenio ? '#76b2fe' : '',
                                                                          }}>
                                                                   
                                                                            <td valign='middle' className="text-nowrap text-center">{item.dapcons_codigo ? item.dapcons_codigo : 'DAPcons ' + item.id}

                                                                            {
                                                                                item.id_padre &&
                                                                                <>
                                                                                 <br/><span className="mt-2 text-success-dapcons">Renovado a partir de N° {item.id_padre}</span>
                                                                                 </>
                                                                            }
                                                                            </td>
                                                                            {
                                                                                !isModuleOrganizacion(modulo_app) &&
                                                                                <td valign='middle' className=''>{item.organizacion?.org_nombre}</td>
                                                                            }

                                                                            <td valign='middle'>{item2.dv_nombre_producto}</td>
                                                                            {
                                                                                isModuleOrganizacion(modulo_app) &&
                                                                                <td valign='middle'>{item.consultor?.consultor_nombre + ' ' + item.consultor?.consultor_apellidos}</td>
                                                                            }
                                                                            {/* <td valign='middle'>{item.verificador?.consultor_nombre + ' ' + item.verificador?.consultor_apellidos}</td> */}
                                                                            <td valign='middle'>
                                                                                {!item.es_pagado 
                                                                                    ?
                                                                                        <span>PENDIENTE DE PAGO</span>
                                                                                    :
                                                                                    <>
                                                                                    {item.dapcons_estado_param_id === 'pendiente_verificar_rcp' ?
                                                                                        fetchEstadosDAPconsData.data?.find(item2 => item2.id === 'pendiente_verificar_rcp')?.nombre
                                                                                        :
                                                                                        fetchEstadosDAPconsData.data?.find(item2 => item2.id === item['idiomas']?.[index2]?.dapcons_idioma_estado)?.nombre
                                                                                    }
                                                                                    </>
                                                                                }
                                                                            </td>
                                                                            <td valign='middle' className='text-nowrap text-center'>{item['idiomas']?.[index2]?.dapcons_fecha_estado?.split(' ')?.[0]}</td>

                                                                            <td valign='middle' className='text-nowrap text-center'>{item.dapcons_valido_hasta}</td>
                                                                            {/* {console.log(idiomas[item['idiomas'][index2]['idioma_id']])} */}
                                                                            <td valign='middle' className='text-nowrap text-center icon-flag'>
                                                                                <img src={imagesFlags(`./${item['idiomas'][index2]['idioma_id']}.png`)} alt="" />
                                                                            </td>
                                                                            {
                                                                                isModuleAdministrador(modulo_app) &&
                                                                                <td valign='middle' className='text-nowrap text-center text-success'>
                                                                                    {item['idiomas'][index2]['inscripcion_ecoplatform'] && 'Si'}
                                                                                </td>
                                                                            }
                                                                            {
                                                                                isModuleAdministrador(modulo_app) &&
                                                                                <td valign='middle' className='text-nowrap text-center'>
                                                                                    {item.organizacion.org_convenio  ? <><span className=" text-success">Si</span></> : 'No'}
                                                                                </td>
                                                                            }
                                                                            {
                                                                                isModuleAdministrador(modulo_app) &&
                                                                                <td valign='middle' className='text-nowrap text-center'>{item.secuencia}</td>
                                                                            }
                                                                            <td valign='middle' className='text-center'>
                                                                                {
                                                                                    (item.dapcons_estado_param_id !== 'pendiente_verificar_rcp' || isModuleAdministrador(modulo_app) || isModuleOrganizacion(modulo_app)) &&
                                                                                    <OverlayTrigger
                                                                                        key={index2 + '1'}
                                                                                        placement={'top'}
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                                                {Lang('VER_MAS')}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <Button variant="transparent" size="sm" className="text-primary"
                                                                                            onClick={() => history.push({
                                                                                                pathname: `/DAPcons/${item.id}/${item['idiomas'][index2]['idioma_id']}`,
                                                                                                // state: {
                                                                                                //     idiomaDAPconURL: item['idiomas'][index2]['idioma_id']
                                                                                                // }
                                                                                            })}
                                                                                        >   
                                                                                            {/*faBook */}
                                                                                            <FontAwesomeIcon icon={faEye} />
                                                                                        
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                }   
                                                                                {(isModuleOrganizacion(modulo_app) && !item.id_hijo && item.is_expired) &&
                                                                                    <OverlayTrigger
                                                                                        key={index2 + '2'}
                                                                                        placement={'top'}
                                                                                        overlay={
                                                                                            <Tooltip id={`tooltip-${item.id}`}>
                                                                                                {'Renovar'}
                                                                                            </Tooltip>
                                                                                        }
                                                                                    >
                                                                                        <Button variant="transparent" size="sm" className="text-danger"
                                                                                                onClick={() => handleOpenModal_Duplicar({
                                                                                                    action: 'DUPLICAR',
                                                                                                    id: item.id
                                                                                                })}
                                                                                            >   
                                                                                            <FontAwesomeIcon icon={faClone} />
                                                                                        </Button>
                                                                                    </OverlayTrigger>
                                                                                }
                                                                                { isAdmin(user.perfil) &&
                                                                                    <OverlayTrigger
                                                                                            key={index2 + '5'}
                                                                                            placement={'top'}
                                                                                            overlay={
                                                                                                <Tooltip id={`tooltip-${item.id}`}>
                                                                                                    {'Eliminar Dapcons'}
                                                                                                </Tooltip>
                                                                                            }
                                                                                        >
                                                                                        <Button variant="transparent" size="sm" className='text-danger'
                                                                                            onClick={
                                                                                            () => handleOpenModal_Confirm({
                                                                                                show: 'DELETE',
                                                                                                id: item.id
                                                                                            })
                                                                                            }
                                                                                        >
                                                                                            <FontAwesomeIcon icon={faTrashAlt}/>
                                                                                        </Button>
                                                                                     </OverlayTrigger>   
                                                                                }
                                                                            </td>
                                                                        </tr>
                                                                    )
                                                                })
                                                            }
                                                        </>
                                                    })}
                                                </tbody>
                                            </Table>
                                            <PaginationT
                                                total={fetchDAPconsData?.data?.data?.data?.total}
                                                handleFormChange={handleFormChange}
                                                formValuesSearch={formValuesSearch}
                                                perPage={fetchDAPconsData?.data?.data?.data?.per_page}
                                            />

                                            <div className="text-center mt-3">
                                                <p className="text-muted">
                                                    Total filtrado: {fetchDAPconsData?.data?.data?.data?.total} DAPcons
                                                </p>
                                            </div>

                                           

                                        </>
                                        :
                                        <h5 className='my-0 py-5 text-center'>No se han encontrado resultados</h5>
                                }
                            </>
                    }
                </div>
            </div>
            <ModalCrearDAPcons
                data={dataModal_Dapcons}
                handleCloseModal={handleCloseModal_Dapcons}
                handleShowModalSuccess={handleShowModalSuccess}
                //showModalCrearDAPcons={showModalCrearDAPcons}
                //handleCloseModalCrearDAPcons={handleCloseModalCrearDAPcons}
                //fetchRCPData={fetchRCPData}
                fetchDAPcons={fetchDAPcons}
                formValuesSearch={formValuesSearch}
                //fetchVerificadoresData={fetchVerificadoresData}
                fetchIdiomasData={fetchIdiomasData}
                fetchCrearDAPcons={fetchCrearDAPcons}
                fetchCrearDAPconsData={fetchCrearDAPconsData}
            />

            <ModalDuplicar
                data={dataModal_Duplicar}
                handleCloseModal={handleCloseModal_Duplicar}
                history={history}
            />

            <ModalEliminarDapcons
                data={dataModal_Confirm}
                handleCloseModal={handleCloseModal_Confirm}
                fetchDAPcons={fetchDAPcons}
                formValuesSearch={formValuesSearch}
            />

            <Modal show={showModalSuccess} onHide={handleCloseModalSuccess} backdrop='static'>
                {/* <Modal.Header closeButton className='border-0 pt-4'>
                    <Modal.Title className='h5'>{title}</Modal.Title>
                </Modal.Header> */}
                <Modal.Body>
                    <div className="py-0 text-center">
                        <h5 className='my-4 fw-normal'>
                            <FontAwesomeIcon icon={faCheckCircle} className='me-2 fa-2x text-success' />
                        </h5>
                        <p className=''>
                            Se ha creado el DAPcons con exito.
                        </p>
                        <p className='mb-0'>
                            A partir de este momento el consultor asignado deberá acceder a la plataforma y seguir el procedimiento para introducir los datos necesarios y seguir con el proceso de la DAPcons.
                        </p>
                    </div>
                </Modal.Body>
                <Modal.Footer className='border-0'>
                    <Button variant="primary" onClick={handleClickContinuar}>
                        Continuar
                    </Button>
                </Modal.Footer>
            </Modal>

            <ModalStatusServer2 fetchData={fetchDAPconsExcelData} onlyError/>

        </>
    )
}
