import React, {useState} from "react";
import { useHistory } from 'react-router-dom'
import { useAxios } from "../../../../hooks/useAxios";
import { useEffect } from "react";
import { isStatusSucess, getDataFile } from "../../../../helpers/helpers";
import { Button, Form, Table, Alert, Badge } from "react-bootstrap";
import { Loader } from "../../../../components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTrash, faUser, faList, faCreditCard, faCheckSquare, faCogs, faFilter, faLink, faEdit, faFileExcel } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "../../../../hooks/useForm";
import { ModalStatusServer2 } from "../../../../components/ModalStatusServer/ModalStatusServer2";
import { ButtonSpinner } from "../../../../components/ButtonSpinner/ButtonSpinner";
import {ModalPayment} from './ModalPayment'
import {ModalEditPayment} from './ModalEditPayment'
import {ModalFilter} from './ModalFilter'
import { useModalData } from '../../../../hooks/useModalData';
import { PaginationT } from '../../../../components/PaginationT/PaginationT';

export const Comisiones = ({fetchConsultorData, listMenu }) => {
    const history = useHistory()
    const [fetchComisiones, fetchComisionesData] = useAxios('get', `/comisiones`)
    const [fetchComisionesTotal, fetchComisionesTotalData] = useAxios('get', `/comisiones`)
    const [fetchComisionExcel, fetchComisionExcelData, resetfetchComisionExcelData]
        = useAxios('POST', `/comisiones/generar_excel`, '', 'blob')
    
    const [dataModal_Payment, handleOpenModal_Payment, handleCloseModal_Payment] = useModalData()
    const [dataModal_Payment_Edit, handleOpenModal_Payment_Edit, handleCloseModal_Payment_Edit] = useModalData()
    const [dataModal_Filter, handleOpenModal_Filter, handleCloseModal_Filter] = useModalData()

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
        es_pagado: '',
        no_pagado: '',
        fecha_desde: '',
        fecha_hasta: '',
        tipo: '',
        limit: 20,
        paginate: true
    });

    const reloadComisiones = (rema_id) => {
        fetchComisiones({
            params: {
                ...formValuesSearch,
                rema_id: fetchConsultorData.data?.id,
                limit: 20,
                paginate: true
            }
        })
        fetchComisionesTotal({
            params:{
                 rema_id: fetchConsultorData.data?.id,
                es_pagado: true
            }
        })
    }

    const handleSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        handleCloseModal_Filter();
        if (formValuesSearch.page !== 1) {
            fetchComisiones({
                params: {
                    ...formValuesSearch,
                    rema_id: fetchConsultorData.data?.id,
                    page: 1,
                }
            })
        } else {
            fetchComisiones({
                params: {
                    ...formValuesSearch,
                    rema_id: fetchConsultorData.data?.id,
                }
            })
        }
    }

    useEffect(() => {
        if (isStatusSucess(fetchConsultorData.status)) {
            const id = fetchConsultorData.data?.id;
            fetchComisiones({
                params: {
                    ...formValuesSearch,
                    rema_id: id,
                    paginate: true
                }
            })
            fetchComisionesTotal({
                params:{
                    rema_id: id,
                    es_pagado: true
                }
            })
        }
    }, [listMenu, formValuesSearch.page]);

    const [selectedRows, setSelectedRows] = useState([]);
    const [totalCommission, setTotalCommission] = useState(0);
    const [totalPaid, setTotalPaid] = useState(0);

    const handleCheckboxChange = (id, commission, checked) => {
        const commissionValue = parseFloat(commission) || 0;
        if (checked) {
          setSelectedRows(prevRows => [...prevRows, id]);
          setTotalCommission(prevTotal => prevTotal + commissionValue);
        } else {
          setSelectedRows(prevRows => prevRows.filter(rowId => rowId !== id));
          setTotalCommission(prevTotal => prevTotal - commissionValue);
        }
    };

    useEffect(() => {
        if (fetchComisionesTotalData.data?.data?.data) {
           const data = fetchComisionesTotalData.data?.data?.data;
           calculateTotal(data);
        }
    }, [fetchComisionesTotalData.data])

    const calculateTotal = (data) => {
        const total = data
          .filter(item => item.fecha_pago)
          .reduce((sum, item) => sum + parseFloat(item.total_comision), 0);

        setTotalPaid(total);
    };

    const handleClickDownloadFile = () => {
        fetchComisionExcel({
            body: {
                pagoids: selectedRows
            }
        })
    }

    useEffect(() => {

        if (fetchComisionExcelData.data) {
            getDataFile(fetchComisionExcelData, resetfetchComisionExcelData, 'comisiones', 'xlsx')
        }
    }, [fetchComisionExcelData])

    return (
        <>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
            {
                fetchComisionesData?.loading ?
                    <div className="py-5 d-flex justify-content-center">
                        <Loader />
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-start mb-3">
                            <div>
                                <h5 className='mt-0'>Comisiones</h5>
                             </div>
                             <div className='d-flex justify-content-end ms-auto'>
                                <Button variant="outline-primary" className="px-3 ms-3 d-flex align-items-center"
                                onClick={
                                    () => handleOpenModal_Filter({
                                        show: 'FILTRO',
                                    })
                                    }
                                >
                                    <FontAwesomeIcon icon={faFilter}/>
                                </Button>
                            </div>
                        </div>
                        {totalCommission > 0 &&
                            <>
                            <div className="d-flex justify-content-center mb-3">
                                <Button variant="primary" size="sm" className=''
                                 onClick={
                                    () => handleOpenModal_Payment({
                                        show: 'PAGAR',
                                    })
                                    }
                                >
                                    <FontAwesomeIcon icon={faCreditCard} className="me-2" /> Pagar
                                </Button>

                                <ButtonSpinner
                                    variant="success" type="submit" className="ms-3" size="sm"
                                    handleSubmit={handleClickDownloadFile} fetchDataLoading={[fetchComisionExcelData.loading]}
                                    value='Generar Excel' icon={<FontAwesomeIcon icon={faFileExcel} className='ms-2' />}
                                />

                            </div>
             
                            <div className="text-center mb-3">
                                <strong className="text-danger">Total a pagar: € {totalCommission.toFixed(2)}</strong>
                            </div>
                            </>
                        }
                        <Table hover bordered responsive className='tableMain'>
                            <thead>
                                <tr>
                                    <th className='text-nowrap text-center'><FontAwesomeIcon icon={faList} /></th>
                                    <th className='text-nowrap text-center'>Organización</th>
                                    <th className='text-nowrap text-center'>Tipo de Comisión</th>
                                    <th className='text-nowrap text-center'>Fecha pago</th>
                                    <th className='text-nowrap text-center'>Importe</th>
                                    <th className='text-nowrap text-center'>Comisión</th>
                                    <th className="text-nowrap text-center"><FontAwesomeIcon icon={faCogs}/></th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                            {
                                fetchComisionesData.loading ?
                                    <tr>
                                        <td className='text-center' colSpan={6}>
                                            <Loader />
                                        </td>
                                    </tr>
                                    :
                                    fetchComisionesData.data?.data?.data?.data?.length > 0 ? <>
                                        {
                                            fetchComisionesData.data?.data?.data?.data?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                        {!item.fecha_pago ?
                                                            <Form.Check
                                                              type="checkbox"
                                                              onChange={e => handleCheckboxChange(item.id, item.total_comision, e.target.checked)}
                                                              checked={selectedRows.some((row) => row === item.id)}
                                                            />
                                                            : <FontAwesomeIcon className="text-success" icon={faCheckSquare}/>
                                                        }   
                                                        </td>
                                                        <td>
                                                            <u><span style={{cursor:'pointer'}} onClick={() => history.push(`/empresas/${item?.organizacion?.org_cif}`)}>{item?.organizacion?.org_nombre}</span></u>
                                                        </td>
                                                        <td>
                                                            <span className="text-dark">{item.tipo_comision_nombre} {item.year || ''}</span>
                                                        </td>
                                                        <td>
                                                           {item.fecha_pago ? <><span className="text-success">{new Date(item.fecha_pago)?.toLocaleDateString()}</span></> : <><span className="text-danger">No</span></>}
                                                        </td>
                                                        <td>
                                                            € {item.importe}
                                                        </td>
                                                        <td>
                                                            € {item.total_comision}
                                                        </td>
                                                        <td className="shrink-td">
                                                            <Button variant="transparent" size="sm" className='text-primary'
                                                                onClick={
                                                                () => handleOpenModal_Payment_Edit({
                                                                    show: 'UPDATE',
                                                                    id: item.id
                                                                })
                                                                }
                                                            >
                                                                <FontAwesomeIcon icon={faEdit}/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </> : <tr>
                                        <td className='py-5 text-center' colSpan={6}>
                                            <h5>No hay datos a mostrar</h5>
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </Table>
                        <PaginationT
                            total={fetchComisionesData.data?.data?.data?.total}
                            handleFormChange={handleFormChange}
                            formValuesSearch={formValuesSearch}
                            perPage={fetchComisionesData.data?.data?.data?.per_page}
                        />
      
                        <div className="text-end mb-3">
                            <strong className="text-success">Total pagado: € {totalPaid.toFixed(2)}</strong>
                        </div>
                    </>
                }
            </div>
            <ModalPayment
                data={dataModal_Payment}
                handleCloseModal={handleCloseModal_Payment}
                total={totalCommission}
                selectedRows={selectedRows}
                reloadComisiones={reloadComisiones}
                setTotalCommission={setTotalCommission}
            />

            <ModalEditPayment
                data={dataModal_Payment_Edit}
                handleCloseModal={handleCloseModal_Payment_Edit}
                total={totalCommission}
                selectedRows={selectedRows}
                reloadComisiones={reloadComisiones}
                setTotalCommission={setTotalCommission}
            />

            <ModalFilter
                data={dataModal_Filter}
                handleCloseModal={handleCloseModal_Filter}
                handleFormChange={handleFormChange}
                formValuesSearch={formValuesSearch}
                handleInputChangeSearch={handleInputChangeSearch}
                handleSearch={handleSearch}
            />
        </>
    );
};
