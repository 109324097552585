import React from "react";
import { Switch, Route, Redirect, useParams } from 'react-router-dom';
import { Consultor } from "./Consultor";
import { Breadcrumb } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { ListMenuRoutes } from "../../../components/ListMenu/ListMenuRoutes";
import { useLocation } from 'react-router-dom';
import { ListMenu } from "../../../components/ListMenu/ListMenu";
import { useState } from "react";
import { useEffect } from "react";
import { isStatusSucess } from "../../../helpers/helpers";
import { useHistory } from 'react-router-dom';
import { GetConsultorJSON, PostConsultorJSON } from "../../../services/consultor.service";
import { Loader } from '../../../components/Loader/Loader';
import {Comisiones} from './Comisiones/Comisiones'

export const ConsultorRouter = ({ match }) => {
	const { id } = useParams();
    const { search } = useLocation();
    const history = useHistory();
    const valueParamsTab = (new URLSearchParams(search)).get('tab') || ''
    const [iDactive, setiDactive] = useState(valueParamsTab || 'datosGenerales');

    const [listMenu, setListMenu] = useState({
        datosGenerales: {
            title: 'Datos generales'
        }
    });

    /*let listMenu = {
        datosGenerales: {
            title: 'Datos generales'
        },
        /*comisiones: {
            title: 'Comisiones'
        },*/
    //}

    const [fetchConsultor, fetchConsultorData, resetFetchConsultor] = GetConsultorJSON()
    useEffect(() => {
        if (iDactive === 'datosGenerales' || (valueParamsTab && !fetchConsultorData.data)) {
            fetchConsultor({
                params: {
                    consultor_nif: match.params.id
                }
            })
        }
    }, [iDactive])

    useEffect(() => {
        if (fetchConsultorData.status === 200) {
            const updatedListMenu = { ...listMenu };
            if (fetchConsultorData.data?.es_consultor_rema) {
                updatedListMenu.comisiones = {
                    title: 'Comisiones'
                };
            }
            setListMenu(updatedListMenu);
        }
    }, [fetchConsultorData])

     return (
        <div>

            <Breadcrumb className="Breadcrumb-inner">
                <Breadcrumb.Item href="#">
                    <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
                <Breadcrumb.Item onClick={() => history.push('consultores')}>
                     Mis datos
                </Breadcrumb.Item>
                <Breadcrumb.Item active>
                  <FontAwesomeIcon icon={faHome} />
                </Breadcrumb.Item>
            </Breadcrumb>

            <div className="row d-flex justify-content-center">
                <div className="col-xl-3 mb-3">
                    <div className="shadow-sm bg-white p-2">
                        <ListMenu
                            values={listMenu}
                            setiDactive={setiDactive}
                            iDactive={iDactive}
                        />
                    </div>
                </div>
                <div className="col-12 col-xl-9">
                    {
                        iDactive === 'datosGenerales' &&
                        <Consultor
                            match={match}
                            fetchConsultorData={fetchConsultorData}
                            fetchConsultor={fetchConsultor}
                        />
                    }
                    {
                        iDactive === 'comisiones' &&
                        <Comisiones
                            fetchConsultorData={fetchConsultorData}
                            fetchConsultor={fetchConsultor}
                            listMenu={listMenu}
                        />
                        // fetchOrganizacionData={fetchOrganizacionData}
                    }
                </div>
            </div>
        </div>
     )   

}