import React, {useState} from "react";
import { useHistory } from 'react-router-dom';
import { useAxios } from "../../../../hooks/useAxios";
import { useEffect } from "react";
import { isStatusSucess } from "../../../../helpers/helpers";
import { Button, Form, Table, Alert, Badge } from "react-bootstrap";
import { Loader } from "../../../../components/Loader/Loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle, faTrash, faUser, faFilter, faLink } from "@fortawesome/free-solid-svg-icons";
import { useForm } from "../../../../hooks/useForm";
import { ModalStatusServer2 } from "../../../../components/ModalStatusServer/ModalStatusServer2";
import { ButtonSpinner } from "../../../../components/ButtonSpinner/ButtonSpinner";
import {ModalRema} from './ModalRema';
import {ModalSincronizar} from './ModalSincronizar'
import {ModalConfirm} from './ModalConfirm'
import {ModalFilter} from './ModalFilter'
import { useModalData } from '../../../../hooks/useModalData';
import { PaginationT } from '../../../../components/PaginationT/PaginationT';

export const Comisiones = ({fetchOrganizacionData, iDactive}) => {
    const history = useHistory();
    const [fetchComisiones, fetchComisionesData] = useAxios('get', `/comisiones`)
    const [consultor, setConsultor] = useState({
            isConsultor: '',
            fullName: '',
            org_id: '',
            nif: ''
    });

    const [dataModal_Rema, handleOpenModal_Rema, handleCloseModal_Rema] = useModalData()
    const [dataModal_Sincronizar, handleOpenModal_Sincronizar, handleCloseModal_Sincronizar] = useModalData()
    const [dataModal_Confirm, handleOpenModal_Confirm, handleCloseModal_Confirm] = useModalData()
    const [dataModal_Filter, handleOpenModal_Filter, handleCloseModal_Filter] = useModalData()

    const [formValuesSearch, handleInputChangeSearch, handleFormChange] = useForm({
        page: 1,
        es_pagado: '',
        no_pagado: '',
        fecha_desde: '',
        fecha_hasta: '',
        tipo: '',
        limit: 20,
        paginate: true
    });

    const handleSearch = (e) => {
        if (e) {
            e.preventDefault();
        }
        handleCloseModal_Filter();
        if (formValuesSearch.page !== 1) {
            fetchComisiones({
                params: {
                    ...formValuesSearch,
                    org_id: fetchOrganizacionData.data?.id,
                    page: 1,
                }
            })
        } else {
            fetchComisiones({
                params: {
                    ...formValuesSearch,
                    org_id: fetchOrganizacionData.data?.id,
                }
            })
        }
    }

    const reloadComisiones = (rema_id) => {
        fetchComisiones({
                params: {
                    ...formValuesSearch,
                    org_id: fetchOrganizacionData.data?.id,
                    page: 1,
                }
        })
    }

    useEffect(() => {
        if (fetchOrganizacionData.data) {
            const org_id = fetchOrganizacionData.data?.id;
            const consultor_id = fetchOrganizacionData.data?.rema_id;
            const fullName = fetchOrganizacionData.data?.rema?.consultor_nombre +' '+fetchOrganizacionData.data?.rema?.consultor_apellidos +' '+fetchOrganizacionData.data?.rema?.consultor_nif;
            setConsultor({...consultor, isConsultor: consultor_id, fullName: fullName, org_id: org_id, nif: fetchOrganizacionData.data?.rema?.consultor_nif});
            fetchComisiones({
                params: {
                    ...formValuesSearch,
                    org_id,
                }
            })
        }
    }, [fetchOrganizacionData.data, formValuesSearch.page]);

    return (
        <>
            <div className="MainInner shadow-sm animate__animated animate__fadeInUp">
            {
                fetchOrganizacionData?.loading ?
                    <div className="py-5 d-flex justify-content-center">
                        <Loader />
                    </div>
                    :
                    <>
                        <div className="d-flex justify-content-start mb-3">
                            <div>
                             <h5 className='mt-0'>Comisiones</h5>
                            </div>
                             <div className='d-flex justify-content-end ms-auto'>
                                <Button variant="outline-primary" className="px-3 ms-3 d-flex align-items-center"
                                onClick={
                                    () => handleOpenModal_Filter({
                                        show: 'FILTRO',
                                    })
                                    }
                                >
                                    <FontAwesomeIcon icon={faFilter}/>
                                </Button>
                            </div> 
                        </div>
                        <div className="d-flex justify-content-end mb-3">
                            <Button variant="default" size="sm" className=''
                            onClick={
                                    () => handleOpenModal_Rema({
                                        show: 'UPDATE',
                                    })
                                }
                            >
                                <FontAwesomeIcon icon={faUser} className="me-2" /> {consultor.isConsultor ? 'Rema asignado : '+consultor.fullName  : 'Definir REMA'}
                            </Button>

                            {consultor.isConsultor &&
                                <>
                                <Button variant="warning" size="sm" className="ms-2"
                                    onClick={() => history.push(`/consultores/${consultor.nif}`)}
                                >
                                    <FontAwesomeIcon icon={faLink} /> Consultor
                                </Button>

                                <Button variant="primary" size="sm" className='ms-2'
                                onClick={
                                    () => handleOpenModal_Sincronizar({
                                        show: 'SINCRONIZAR',
                                    })
                                }
                                >
                                    <FontAwesomeIcon icon={faInfoCircle} className="me-2" /> Sincronizar
                                </Button>
                                </>
                            } 
                        </div>
                        {!consultor.isConsultor ? 
                            <>
                                <Alert variant="warning">
                                 No se ha definido ningún consultor
                                </Alert>
                            </>
                        :
                        <>
                        <Table hover bordered responsive className='tableMain'>
                            <thead>
                                <tr>
                                    <th className='text-nowrap text-center'>REMA</th>
                                    <th className='text-nowrap text-center'>Tipo</th>
                                    <th className='text-nowrap text-center'>Importe</th>
                                    <th className='text-nowrap text-center'>Comisión</th>
                                    <th className='text-nowrap text-center'>Fecha pago</th>
                                    <th className='text-nowrap text-center'>Año</th>
                                    <th className="text-nowrap text-center">Acciones</th>
                                </tr>
                            </thead>
                            <tbody className="text-center">
                            {
                                fetchComisionesData.loading ?
                                    <tr>
                                        <td className='text-center' colSpan={7}>
                                            <Loader />
                                        </td>
                                    </tr>
                                    :
                                    fetchComisionesData.data?.data?.data?.data?.length > 0 ? <>
                                        {
                                            fetchComisionesData.data?.data?.data?.data?.map((item, index) => {
                                                return (
                                                    <tr key={index}>
                                                        <td>
                                                            {item.consultor?.consultor_nombre} {item.consultor?.consultor_apellidos}
                                                        </td>
                                                        <td>
                                                            <span className="text-dark">{item.tipo_comision_nombre}</span>
                                                        </td>
                                                        <td>
                                                            € {item.importe}
                                                        </td>
                                                        <td>
                                                            € {item.total_comision}
                                                        </td>
                                                        <td>
                                                           {item.fecha_pago ? (new Date(item.fecha_pago)?.toLocaleDateString()) : <><span className="text-danger">No</span></>}
                                                        </td>
                                                        <td>
                                                           {item.year}
                                                        </td>
                                                        <td className="shrink-td">
                                                            <Button disabled={item.fecha_pago ? true : false} variant="danger" size="sm" className=''
                                                                onClick={() => handleOpenModal_Confirm({
                                                                    action: 'DELETE',
                                                                    id:item.id
                                                                })}
                                                            >
                                                                <FontAwesomeIcon icon={faTrash}/>
                                                            </Button>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </> : <tr>
                                        <td className='py-5 text-center' colSpan={7}>
                                            <h5>No hay datos a mostrar</h5>
                                        </td>
                                    </tr>
                            }
                            </tbody>
                        </Table>
                        <PaginationT
                            total={fetchComisionesData.data?.data?.data?.total}
                            handleFormChange={handleFormChange}
                            formValuesSearch={formValuesSearch}
                            perPage={fetchComisionesData.data?.data?.data?.per_page}
                        />
                        </>
                       }
                    </>
                }

                <ModalRema
                    data={dataModal_Rema}
                    handleCloseModal={handleCloseModal_Rema}
                    consultor={consultor}
                    setConsultor={setConsultor}
                    reloadComisiones={reloadComisiones}
                />

                <ModalSincronizar
                    data={dataModal_Sincronizar}
                    handleCloseModal={handleCloseModal_Sincronizar}
                    consultor={consultor}
                    setConsultor={setConsultor}
                    reloadComisiones={reloadComisiones}
                />

                <ModalConfirm
                    data={dataModal_Confirm}
                    handleCloseModal={handleCloseModal_Confirm}
                    consultor={consultor}
                    setConsultor={setConsultor}
                    reloadComisiones={reloadComisiones}
                />

                <ModalFilter
                    data={dataModal_Filter}
                    handleCloseModal={handleCloseModal_Filter}
                    handleFormChange={handleFormChange}
                    formValuesSearch={formValuesSearch}
                    handleInputChangeSearch={handleInputChangeSearch}
                    handleSearch={handleSearch}
                />
            </div>
        </>
    );
};
